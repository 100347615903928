<template>
  <div class="stats-main-container">
    <ViewLoadingMessage :loading="loading"/>
    <template v-if="!loading">
      <CRow class="mb-4 justify-content-lg-between">
        <CCol lg="12" class="mb-4">
          <h1 class="main-header mb-1" v-translate>Analytics</h1>
        </CCol>
        <CCol sm="12" xl="auto" class="mb-4">
          <div class="d-flex flex-column flex-lg-row align-items-start">
            <DatePickerTimeZone
                @input="onDatePickerInput"
                :timeZoneValue="timeZonePickerValue"
                :extraTimezones="customTimezoneList()"
                :showDatePicker="showDatePicker"
                :showTimeZonePicker="showTimeZonePicker"
                :showTimeZoneText="!showTimeZonePicker"
                :shortcut="datePickerShortcut"/>
          </div>
        </CCol>
      </CRow>
      <!-- Stats highlight -->
      <CRow class="mb-4">
        <CCol>
          <CRow>
            <CCol sm="6" xl v-if="hasPerm('stats.orders.stats') && canViewStatsOrdersTransacted" style="min-height: 110px;">
              <OrdersKpi :is-resort="isResort" :is-activation="isActivation"/>
            </CCol>
            <CCol sm="6" xl v-if="hasPerm('stats.checkins.stats') && canViewStatsCheckins" style="min-height: 110px;">
              <CheckinsKpi :is-activation="isActivation"/>
            </CCol>
            <CCol sm="6" xl v-if="hasPerm('stats.engagement.stats') && isEngagementEnabled">
              <EventBasicKpi/>
            </CCol>
            <CCol sm="6" xl v-if="hasPerm('stats.media.stats') && showPhotographicProduction"
                  style="min-height: 110px;">
              <PhotographicProductionKpi/>
            </CCol>
          </CRow>
        </CCol>
      </CRow>

      <OrdersCard
          ref="ordersCard"
          v-if="hasPerm('stats.orders.stats')"
          :industry="industry"
          :pica-service-id="picaServiceId"
          :show-releases="showReleases"
          :is-activation="isActivation"
          :is-promo-event="isPromoEvent"
          :is-endurance="isEndurance"
          :period-enabled="periodEnabled"
          :is-dataset-too-older="isDatasetTooOlder"
          :get-params="getParams"
          :get-body-params="getBodyParams"
      />

      <CheckinsCard
          ref="checkinsCard"
          v-if="hasPerm('stats.checkins.stats')"
          :industry="industry"
          :pica-service-id="picaServiceId"
          :is-activation="isActivation"
          :period-enabled="periodEnabled"
          :is-dataset-too-older="isDatasetTooOlder"
          :get-params="getParams"
          :get-body-params="getBodyParams"
      />

      <MetricCard
          v-if="hasPerm('stats.engagement.stats') && isEngagementEnabled"
          :date_to="selectedDatesForMetrics().to"
          :date_from="selectedDatesForMetrics().from"
          :need_date="isResort"
          :time_zone="timeZone"
      />

      <PhotographicProductionCard
          v-if="hasPerm('stats.media.stats')"
          ref="photographicProductionCard"
          :pica-service-id="picaServiceId"
          :get-params="getParams"
          :get-body-params="getBodyParams"
          :current-period="period.DAY"
          :show-imago-data="showImagoData"
          :show-photographic-production-table="showPhotographicProductionTable"
          @canView="showPhotographicProduction=true"
      />

    </template>
  </div>

</template>

<script>
import ViewLoadingMessage from "@/components/ViewLoadingMessage"
import moment from "moment";
import {industriesKeys} from "@/views_pica/stats/constants";
import DatePickerTimeZone from "@/components/DatePickerTimeZone.vue";
import {mapActions, mapGetters} from "vuex";
import {getPercentage} from "@/utils/functions";
import {thirdPartyTool} from "@/constants";
import EventBasicKpi from "@/domain/eventMetric/components/EventBasicKPI.vue";
import {period} from "@/domain/eventMetric/consts";
import {EUROPE_ROME} from "@/utils/date";
import OrdersKpi from "@/views_pica/stats/orders/OrdersKpi.vue";
import CheckinsKpi from "@/views_pica/stats/checkins/CheckinsKpi.vue";
import PhotographicProductionKpi from "@/views_pica/stats/photographicProduction/PhotographicProductionKpi.vue";
import OrdersCard from "@/views_pica/stats/orders/OrdersCard.vue";
import CheckinsCard from "@/views_pica/stats/checkins/CheckinsCard.vue";
import PhotographicProductionCard from "@/views_pica/stats/photographicProduction/PhotographicProductionCard.vue";
import MetricCard from "@/views_pica/stats/metrics/MetricCard.vue";

export default {
  name: 'Analytics',
  components: {
    MetricCard,
    PhotographicProductionCard,
    CheckinsCard,
    OrdersCard,
    PhotographicProductionKpi,
    CheckinsKpi,
    OrdersKpi,
    EventBasicKpi,
    DatePickerTimeZone,
    ViewLoadingMessage,
  },
  data() {
    return {
      firstLoad: true,
      loading: true,
      picaServiceId: null,

      picaServices: [],
      showPhotographicProduction: false,

      datePickerShortcut: null,
      timeZonePickerValue: null,
      timeZone: null,
      fromDate: null,
      toDate: null,
      from: null,
      to: null
    }
  },
  computed: {
    ...mapGetters('stats', [
      'eventCurrency',
      'canViewStatsOrdersTransacted',
      'canViewStatsCheckins'
    ]),
    ...mapGetters('eventMetric', {
      metricsConfiguration: 'configuration'
    }),
    ...mapGetters('event', [
      'industry',
      'isPromo',
      'thirdPartyTool',
    ]),
    ...mapGetters('event', {
      eventTimeZone: 'timeZone'
    }),

    showDatePicker() {
      return this.isResort
    },
    showTimeZonePicker() {
      return this.hasPerm('dashboard.view_timezone_select')
    },

    showReleases() {
      return this.isEndurance
    },
    isEndurance() {
      return this.industry === industriesKeys.ENDURANCE
    },
    isResort() {
      return this.industry === industriesKeys.RESORT
    },
    isCorporate() {
      return this.industry === industriesKeys.CORPORATE
    },
    isPromoEvent() {
      return this.isPromo
    },
    isStadium() {
      return this.industry === industriesKeys.STADIUM
    },
    isActivation() {
      return (this.isStadium || this.isCorporate)
    },
    showImagoData() {
      return this.thirdPartyTool === thirdPartyTool.IMAGO
    },
    showPhotographicProductionTable() {
      return !this.isStadium && !this.isCorporate
    },
    period() {
      return period
    },
    periodEnabled() {
      return !this.from && !this.to
    },

    isEngagementEnabled() {
      if (!this.hasPerm('stats.engagement.stats')) return false
      return this.metricsConfiguration !== null
    },
    isDatasetTooOlder() {
      let dates = this.picaServices.map(event => event.event_start_at)
      dates.sort((a, b) => Date.parse(b) - Date.parse(a))
      dates.reverse()
      const olderEventsDate = new Date(dates[0])
      const today = new Date()
      return (today - olderEventsDate) / (1000 * 3600 * 24 * 365) > 1
    }
  },

  async mounted() {
    this.picaServiceId = this.$store.getters['event/picaServiceId']
    this.resetTimeZone(this.eventTimeZone || EUROPE_ROME)
    await this.fetchConfig({type: 'stats', id: this.picaServiceId})
    await this.setMetricsEventId(this.picaServiceId)

    if (this.hasPerm('stats.engagement.stats'))
      await this.fetchMetricConfiguration()

    this.loading = false
  },
  watch: {
    customTimezoneList: function () {
      if (!this.firstLoad) this.getAllData()
    },
  },
  methods: {
    resetTimeZone(value) {
      this.timeZonePickerValue = value
      this.timeZone = value
    },
    onDatePickerInput(value) {
      this.timeZone = value.timezone
      this.from = value.start
      this.to = value.end
      this.fromDate = value.startDate
      this.toDate = value.endDate
      this.getAllData()
    },
    selectedDatesForMetrics() {
      let from, to;
      if (this.fromDate) {
        from = this.fromDate
      } else {
        from = moment('2000-01-01T00:00:00').toDate()
      }
      if (this.toDate) {
        to = this.toDate
      } else {
        to = moment().endOf('day').toDate()
      }
      return {from, to}
    },
    customTimezoneList() {
      return [this.eventTimeZone || EUROPE_ROME]
    },
    getPercentage,
    ...mapActions('eventMetric', {
      fetchMetricConfiguration: 'fetchConfiguration',
      setMetricsEventId: 'setEventId'
    }),
    ...mapActions('stats', [
      'cleanupStats',
    ]),
    ...mapActions('login', [
      'fetchConfig',
    ]),
    getAllData() {
      this.firstLoad = false

      this.cleanupStats()

      if (this.$refs.ordersCard)  this.$refs.ordersCard.getData()
      if (this.$refs.checkinsCard) this.$refs.checkinsCard.getData()
      if (this.$refs.photographicProductionCard)  this.$refs.photographicProductionCard.getData()
    },

    getParams(countBy) {
      return {
        count_by: countBy ? countBy : this.period.DAY.suffix,
        from: this.from,
        to: this.to,
        tz: this.timeZone,
      }
    },
    getBodyParams() {
      return {
        pica_service_ids: [this.picaServiceId]
      }
    }
  },
  beforeDestroy() {
    this.cleanupStats()
  }
}
</script>

